import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import img from "../../images/Rectangle 6.png";
import { IoStar } from "react-icons/io5";
import { FiMinus, FiPlus } from "react-icons/fi";
import customer from "../../images/customer.png";
import "./ProductDetails.css";
import Footer from "../../components/layout/footer";
import img1 from "../../images/Rectangle 169.png";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useCartContext } from "../../Context/CartContext";
import { IoShareSocial } from "react-icons/io5";
import SliderHome from "../../components/SacredOfferingsSlider/SliderHome";
import { FaRegStar, FaStar } from "react-icons/fa6";

const ProductDetails = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const stars = Array.from({ length: 5 });
  const [showDescription, setShowDescription] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const [orderQuantity, setOrderQuantity] = useState(1);
  const [cartItems, setCartItems] = useState([]);
  const { fetchCartCount } = useCartContext();
  const [rating, setRating] = useState(0);
  const [selectedPhoto, setSelectedPhoto] = useState(img);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const userId = localStorage.getItem("userId");
      try {
        const response = await axios.get(
          `${BASE_URL}/products/productId?userId=${userId}&id=${productId}`
        );
        setProductDetails(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    if (
      productDetails &&
      productDetails.productPhotos &&
      productDetails.productPhotos?.length > 0
    ) {
      setSelectedPhoto(productDetails.productPhotos[0].photoName);
    }
  }, [productDetails]);

  // Function to fetch cart items
  const fetchCartItems = async () => {
    const userId = localStorage.getItem("userId");
    console.log("userId:", userId);
    if (userId) {
      try {
        const response = await axios.post(
          `${BASE_URL}/usercart/in-cart?userId=${userId}`
        );
        setCartItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const handleDescriptionClick = () => {
    setShowDescription(true);
  };

  const handleReviewsClick = () => {
    setShowDescription(false);
  };

  const decreaseQuantity = () => {
    if (orderQuantity > 1) {
      setOrderQuantity(orderQuantity - 1);
    }
  };

  const increaseQuantity = () => {
    setOrderQuantity(orderQuantity + 1);
  };

  const handleAddToCart = async (productId) => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      try {
        const existingCartItemResponse = await axios.post(
          `${BASE_URL}/usercart/in-cart?userId=${userId}`
        );

        const existingCartItem = existingCartItemResponse.data.find(
          (item) => item.productId === productId
        );

        if (existingCartItem) {
          // If the product is already in the cart, navigate to the cart page directly
          navigate("/cart");
        } else {
          // If the product is not in the cart, add it to the cart
          await axios.post(
            `${BASE_URL}/usercart/add-product?userId=${userId}&productId=${productId}&orderQuantity=${orderQuantity}`
          );
          // Navigate to the cart page after adding to the cart
          navigate("/cart");
        }
        await fetchCartCount();
      } catch (error) {
        console.error("Error adding product to cart:", error);
        toast.error("Something went wrong! Try again later");
      }
    } else {
      navigate("/signup");
    }
  };

  const handleShare = (productId) => {
    if (navigator.clipboard) {
      const productDetailsUrl = `${window.location.origin}/productdetails/${productId}`;
      navigator.clipboard.writeText(productDetailsUrl);
      toast.success("Product link copied");
    }
  };

  const handleSubmitReview = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const userName = formData.get("userName");
    const email = formData.get("email");
    const productRating = formData.get("productRating");
    const message = formData.get("message");

    try {
      const response = await axios.post(`${BASE_URL}/product-ratings`, {
        productId: productId,
        userName: userName,
        email: email,
        message: message,
        ratings: productRating,
      });
      console.log(response.data);
      toast.success("Thank you for your rating! We appreciate your feedback.");
      event.target.reset();
      setRating(0);
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("Error submitting review. Please try again later.");
    }
  };

  const handleRatingChange = (event) => {
    const value = event.target.value;
    setRating(parseInt(value));
  };

  const getGalleryPhotos = (photos) => {
    const galleryPhotos = [];
    if (photos) {
      photos.forEach((photo) => {
        if (photo.photoName2) galleryPhotos.push(photo.photoName2);
        if (photo.photoName3) galleryPhotos.push(photo.photoName3);
        if (photo.photoName4) galleryPhotos.push(photo.photoName4);
        if (photo.photoName5) galleryPhotos.push(photo.photoName5);
        if (photo.photoName6) galleryPhotos.push(photo.photoName6);
        if (photo.photoName7) galleryPhotos.push(photo.photoName7);
        if (photo.photoName8) galleryPhotos.push(photo.photoName8);
        if (photo.photoName9) galleryPhotos.push(photo.photoName9);
        if (photo.photoName10) galleryPhotos.push(photo.photoName10);
      });
    }
    return galleryPhotos;
  };

  const galleryPhotos = getGalleryPhotos(productDetails?.productPhotos);

  return (
    <div>
      <Header />
      {productDetails && (
        <div className="product-detail-container">
          <div className="product-image-container">
            <div className="product-image-gallery">
              {galleryPhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Product Image ${index + 1}`}
                  onClick={() => setSelectedPhoto(photo)}
                />
              ))}
            </div>
            <div className="product-single-image">
              <img alt="Main Product" src={selectedPhoto} />
            </div>
          </div>
          <div className="product-details">
            <h3>
              {productDetails.productName} -{" "}
              {productDetails.prdDesc?.slice(0, 100)}...
            </h3>
            <div className="product-rating">
              {" "}
              {stars.map((_, index) => (
                <FaStar key={index} />
              ))}
            </div>
            <div className="product-price">
              <span>₹{productDetails.purRate}</span>
              <span>₹{productDetails.mrp}</span>
              {productDetails.minStk == 0 ? (
                <h5 style={{ textAlign: "left", color: "red" }}>
                  Out of stock
                </h5>
              ) : (
                <h5 style={{ textAlign: "left", color: "green" }}>In stock</h5>
              )}
            </div>
            <p>Quantity</p>
            <div className="quantity-btn">
              <button onClick={decreaseQuantity}>
                <FiMinus />
              </button>
              {orderQuantity}
              <button onClick={increaseQuantity}>
                <FiPlus />
              </button>
            </div>
            <div className="product-buttons">
              {productDetails.minStk > 0 ? (
                <React.Fragment>
                  <button
                    type="submit"
                    className="buy-now"
                    onClick={() => handleAddToCart(productDetails.productId)}
                  >
                    Buy Now
                  </button>
                  {cartItems.some(
                    (item) => item.productid === productDetails.productId
                  ) ? (
                    <button type="submit" className="prod-added-cart-btn">
                      Added to cart
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="prod-cart-btn"
                      onClick={() => handleAddToCart(productDetails.productId)}
                    >
                      Add to cart
                    </button>
                  )}
                </React.Fragment>
              ) : (
                <div>
                  <button type="submit" className="buy-now" disabled>
                    Buy Now
                  </button>
                  <button type="submit" className="prod-cart-btn" disabled>
                    Add to cart
                  </button>
                </div>
              )}
            </div>
            <button
              className="share-btn"
              onClick={() => handleShare(productDetails.productId)}
            >
              Share <IoShareSocial />
            </button>
          </div>
        </div>
      )}
      <div className="description-reviews-container">
        <div className="buttons">
          <button
            onClick={handleDescriptionClick}
            className={`description-btn ${showDescription ? "active" : ""}`}
          >
            Description
          </button>
          <button
            onClick={handleReviewsClick}
            className={`reviews-btn ${!showDescription ? "active" : ""}`}
          >
            Reviews
          </button>
        </div>
        {showDescription ? (
          <DescriptionBox productDetails={productDetails} />
        ) : (
          <ReviewsBox />
        )}
      </div>
      <SliderHome />
      <div className="review-form-container">
        <div className="review-form">
          <img src={img1} />
          <form onSubmit={handleSubmitReview}>
            <h1>Customer Review</h1>
            <p style={{ color: "#ffc35b" }}>
              {stars.map((_, index) => (
                <FaStar key={index} />
              ))}
            </p>
            <p>Your Rating</p>
            <input type="text" name="userName" placeholder="Name" required />
            <input type="email" name="email" placeholder="Email" required />
            <input
              type="number"
              name="productRating"
              placeholder="Rate our product"
              onChange={handleRatingChange}
              min="0"
              max="5"
              required
            />
            <div className="rating-stars">
              {[...Array(5)].map((_, index) => {
                if (index < rating) {
                  return <FaStar key={index} size={22} />;
                } else {
                  return <FaRegStar key={index} size={22} />;
                }
              })}
            </div>
            <textarea name="message" placeholder="Message" required />

            <input type="submit" value={"Submit"} />
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

function DescriptionBox({ productDetails }) {
  if (!productDetails) return null;

  // Splitting prdDesc2 into separate points
  const descPoints =
    productDetails.prdDesc2 !== null
      ? productDetails.prdDesc2?.split("\n")
      : "";

  return (
    <div className="description-box">
      <p>{productDetails.prdDesc}</p>
      <div className="desc-table">
        <table>
          <tbody>
            <tr>
              <td className="bold">Brand</td>
              <td>{productDetails.brand}</td>
            </tr>
            <tr>
              <td className="bold">Box</td>
              <td>{productDetails.box}</td>
            </tr>
            <tr>
              <td className="bold">Purity</td>
              <td>{productDetails.prdPurity}</td>
            </tr>
            <tr>
              <td className="bold">Weight</td>
              <td>{productDetails.weight}g</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="desc-list">
        <p>About Items</p>
        <ul>
          {descPoints?.length > 0
            ? descPoints.map((point, index) => <li key={index}>{point}</li>)
            : ""}
          <li>QTY: {productDetails.productQuantity} sticks per pack</li>
        </ul>
      </div>
    </div>
  );
}

function ReviewsBox() {
  return (
    <div>
      <div className="customer-slider">
        <input type="radio" name="testimonial" id="t-1" />
        <input type="radio" name="testimonial" id="t-2" />
        <input type="radio" name="testimonial" id="t-3" defaultChecked />
        <input type="radio" name="testimonial" id="t-4" />
        <input type="radio" name="testimonial" id="t-5" />

        <div className="customer-testimonials">
          <label className="item" htmlFor="t-1">
            <div className="story">
              <div className="customer-name">
                <img src={customer} width={"70px"} />
                <h4>Raj</h4>
              </div>
              <p>
                LMeet the passionate individuals who bring Divine Depot's vision
                to life. Our team consists of dedicated professionals with
                diverse backgrounds and expertise,{" "}
              </p>
            </div>
          </label>
          <label className="item" htmlFor="t-2">
            <div className="story">
              <div className="customer-name">
                <img src={customer} width={"70px"} />
                <h4>Raj</h4>
              </div>
              <p>
                LMeet the passionate individuals who bring Divine Depot's vision
                to life. Our team consists of dedicated professionals with
                diverse backgrounds and expertise,{" "}
              </p>
            </div>
          </label>
          <label className="item" htmlFor="t-3">
            <div className="story">
              <div className="customer-name">
                <img src={customer} width={"70px"} />
                <h4>Raj</h4>
              </div>
              <p>
                LMeet the passionate individuals who bring Divine Depot's vision
                to life. Our team consists of dedicated professionals with
                diverse backgrounds and expertise,{" "}
              </p>
            </div>
          </label>
          <label className="item" htmlFor="t-4">
            <div className="story">
              <div className="customer-name">
                <img src={customer} width={"70px"} />
                <h4>Raj</h4>
              </div>
              <p>
                LMeet the passionate individuals who bring Divine Depot's vision
                to life. Our team consists of dedicated professionals with
                diverse backgrounds and expertise,{" "}
              </p>
            </div>
          </label>
          <label className="item" htmlFor="t-5">
            <div className="story">
              <div className="customer-name">
                <img src={customer} width={"70px"} />
                <h4>Raj</h4>
              </div>
              <p>
                LMeet the passionate individuals who bring Divine Depot's vision
                to life. Our team consists of dedicated professionals with
                diverse backgrounds and expertise,{" "}
              </p>
            </div>
          </label>
        </div>
        <div class="dots">
          <label for="t-1"></label>
          <label for="t-2"></label>
          <label for="t-3"></label>
          <label for="t-4"></label>
          <label for="t-5"></label>
        </div>
      </div>
    </div>
  );
}
export default ProductDetails;
